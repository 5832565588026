import { useContext, useState } from "react";
import UserView from "../components/UserView";
import UserEdit from "../components/UserEdit";
import Page from "./Page";
import LoginContext from "../LoginContext";
import { saveUser } from "../api";

export default function ProfilePage() {
    const {user, refreshUser} = useContext(LoginContext)
    const [editing, setEditing] = useState(false)
    const [saveError, setSaveError] = useState()

    function startEditProfile() {
        setEditing(true)
    }

    function saveProfile(user) {
        saveUser(user)
            .then(refreshUser)
            .then(() => setEditing(false))
            .then(() => setSaveError(undefined))
            .catch(setSaveError)
    }

    function cancelEdit() {
        setEditing(false)
    }

    return (
        <Page title="AERAS - My Profile">
            {editing ? (
                <UserEdit user={user} onSave={saveProfile} onCancel={cancelEdit} saveError={saveError}/>
            ) : (
                <>
                <UserView user={user}/>
                <button className="m-1 btn btn-primary" onClick={startEditProfile}>Edit Profile</button>
                </>
            )}
        </Page>
    )
}