import React, { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"

import Page from "./Page"
import OnlineReportLog from "../components/OnlineReportLog"
import ReportPurchaseDetail from "../components/ReportPurchaseDetail"

import { getReport, getWsUrlForReportRunLog, startNewRun } from "../api"


export function getLatestRun(report) {
    const latestRun = report?.ReportRuns?.sort((a, b) => new Date(b.runDate).getTime() - new Date(a.runDate).getTime())[0]
    return latestRun?.runDate
}

export function formatLatestRunDateForReport(report) {
    return formatRunDate(getLatestRun(report))
}

export function formatRunDate(runDateOrUndefined) {
    const dateText = runDateOrUndefined ? new Date(runDateOrUndefined).toLocaleDateString() : ""
    return dateText
}

export function formatLatestRunDateTimeForReport(report) {
    return formatRunDateTime(getLatestRun(report))
}

export function formatRunDateTime(runDateOrUndefined) {
    const dateText = runDateOrUndefined ? new Date(runDateOrUndefined).toLocaleString() : ""
    return dateText
}

export function getLatestDownloadUrlForReport(report) {
    return getUrlForReportRun(report?.id, report?.ReportRuns[0])
}

export function getUrlForReportRun(reportId, reportRun) {
    return ((reportId) && (reportRun?.id) && (reportRun.status==='complete')) ? `/api/report/${reportId}/pdf/${reportRun.id}` : ''
}

export function getLogUrlForReportRun(reportId, reportRun) {
    return ((reportId) && (reportRun?.id)) ? `/api/report/${reportId}/log/${reportRun.id}` : ''
}

function getRunInProgress(report) {
    return report.ReportRuns.find((run) => run.status === 'in progress')
}

export default function ReportViewPage() {
    const { reportId } = useParams()

    const [report, setReport] = useState()
    const [loadError, setLoadError] = useState()
    const [refreshTimer, setRefreshTimer] = useState()
    const [expandRuns, setExpandRuns] = useState(false)
    const [showingLogForRunId, setShowingLogForRunId] = useState()

    const loadReport = useCallback(function () {
        setLoadError('')
        getReport(reportId).then(setReport).catch((err) => {
            setLoadError(err.message)
        })
    }, [reportId])

    useEffect(loadReport, [loadReport, refreshTimer])

    useEffect(() => {
        if (!report) return 
        const runInProgress = getRunInProgress(report)
        if (runInProgress) {
            setShowingLogForRunId(runInProgress.id)
            let timeoutId = setTimeout(() => {
                setRefreshTimer(Date.now())
            }, 2000)
            return () => clearTimeout(timeoutId)
        }
    }, [report])

    const showAllRuns = () => setExpandRuns(true)
    const showLastRun = () => setExpandRuns(false)

    const startNewReportRun = () => {
        setLoadError('')
        startNewRun(report.id).then(setReport).catch((err) => {
            setLoadError(err.message)
        })
    }

    const loading = (!report && !loadError)
    const locationsText = report?.quarterSections
    const runDate = formatLatestRunDateTimeForReport(report) || 'Not run yet'
    const downloadLatestUrl = getLatestDownloadUrlForReport(report)
    const showingAllRuns = expandRuns || (downloadLatestUrl === '') || showingLogForRunId
    const clearShowingLog = () => setShowingLogForRunId(undefined)
    
    return (
        <Page title="AERAS - Report View">
            {loading && <i>Loading....</i>}
            {report && (
                <div className="row mt-2">
                    <div className="col mb-2">
                        <h2 className="me-4">{report.projectName}</h2>
                        {/* <button className="btn btn-secondary float-end"><i className="bi-map me-2"></i>Map</button> */}
                        <div className="mb-2">
                            <small className="text-body-secondary">Location</small>
                            <div className="ms-4">{locationsText}</div>
                        </div>
                        <small className="text-body-secondary">Prepared for</small>
                        <div className="ms-4 mb-2">
                            <h6 className="mb-0">{report.contactName}</h6>
                            <small>{report.email}</small>
                        </div>
                        <div className="ms-4 mb-2">
                            <h6 className="mb-0">{report.companyName}</h6>
                            {report.address} <br />
                            {report.city} {report.province} {report.postalCode}
                        </div>
                        {!showingAllRuns && (
                            <div className="mb-2">
                                <div className="d-flex">
                                    <small className="me-auto text-body-secondary">Last run</small>
                                </div>
                                <div className="ms-4">{runDate}</div>
                            </div>
                        )}
                        {showingAllRuns && (
                            <div className="mb-2">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th><small className="text-body-secondary">Run Date</small></th>
                                            <th><small className="text-body-secondary">Link</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {report.ReportRuns.length === 0 && (<tr><td><i>No runs yet</i></td><td></td></tr>)}
                                        {report.ReportRuns.map((run, index) => (
                                            <React.Fragment key={'run' + index}>
                                                <tr>
                                                    <td>{formatRunDateTime(run.runDate)}</td>
                                                    <td>
                                                        { run.status === 'failed' && (
                                                            <div className="text-danger">Failed</div>                                                        
                                                        )}
                                                        { run.status === 'error' && (
                                                            <div className="text-danger">Error - <a className="text-danger" href={getLogUrlForReportRun(report.id, run)} download>Log<i className="bi-download ms-2"></i></a></div>                                                        
                                                        )}
                                                        { run.status === 'in progress' && (
                                                            <div className="text-warning">In progress<div className="ms-1 spinner-border spinner-border-sm"></div></div>                                                        
                                                        )}
                                                        { run.status === 'complete' && (
                                                            <a href={getUrlForReportRun(report.id, run)} download>Download<i className="bi-download ms-2"></i></a>
                                                        )}
                                                    </td>
                                                </tr>
                                                { run.id === showingLogForRunId && (
                                                    <tr key={'inprogress'+index}>
                                                        <td colSpan='2'>
                                                            <OnlineReportLog wsUrl={getWsUrlForReportRunLog(report.id, run.id)} onClose={clearShowingLog}/>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    { loadError && (
                        <div className="ms-2 text-danger">{loadError}</div>
                    )}
                    <div className="col-12">
                        {!showingAllRuns && (
                            <a className="btn btn-primary ms-2 mt-1" href={downloadLatestUrl} download><i className="bi-download me-2"></i>Download</a>
                        )}
                        {showingAllRuns && (
                            <button className="btn btn-secondary ms-2 mt-1" onClick={() => startNewReportRun()}><i className="bi-arrow-clockwise me-2"></i>Re-run</button>
                        )}
                        {!showingAllRuns && (
                            <button className="btn btn-secondary ms-2 mt-1" onClick={showAllRuns}><i className="bi-list-ul me-2"></i>Show All Runs</button>
                        )}
                        {showingAllRuns && (downloadLatestUrl !== '') && !showingLogForRunId && (
                            <button className="btn btn-secondary ms-2 mt-1" onClick={showLastRun}><i className="bi-list-ul me-2"></i>Show Last Run Only</button>
                        )}
                    </div>
                    <ReportPurchaseDetail report={report} reloadReport={loadReport}/>
                </div>
            )}
        </Page>
    )
}