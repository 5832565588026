import { useEffect, useState, useCallback } from "react"
import { getSyncOrderForReport, getPaymentUrlForReport } from "../api"


function createMailToInquiry(report) {
    return 'mailto:info@aerasenv.com?'+
        'subject=Report%20Purchase%20Inquiry&'+
        'body='+
        'Project%20Name%3A%20'+encodeURIComponent(report.projectName)+'%0D%0A'+ 
        'Report%20ID%3A%20'+report.id+'%0D%0A'
}

export default function ReportPurchaseDetail({ report, reloadReport }) {

    const [tryingToFix, setTryingToFix] = useState(false)
    const [unableToSync, setUnableToSync] = useState(false)
    const [startingPurchase, setStartingPurchase] = useState(false)
    const [paymentError, setPaymentError] = useState()

    useEffect(() => {
        if (!report.createdUnderSubscription && report.wixPaymentStatus !== "PAID") {
            setTryingToFix(true)
            getSyncOrderForReport(report.id)
            .then(() => {
                reloadReport()
            })
            .catch((error) => {
                setUnableToSync(true)
            })
            .finally(() => {
                setTryingToFix(false)
            })
        }
    }, [report, reloadReport])

    const purchaseReport = useCallback(() => {
        setStartingPurchase(true)
        getPaymentUrlForReport(report.id)
        .then(({ paymentUrl }) => {
            // off to pay!
            window.location.href = paymentUrl
        })
        .catch((error) => {
            setPaymentError(error.message)
            setStartingPurchase(false)
        })
        .finally(() => {
        })
    }, [report.id])

    return (
        <div className='mt-2'>
            { report.wixOrderNumber && <small className="text-body-secondary">Order number: {report.wixOrderNumber}</small> } 
            { !report.createdUnderSubscription && report.wixPaymentStatus !== "PAID" && ( <>
                <div>There was a problem during processing payment for this report:</div>
                { tryingToFix && <div className="ms-3 text-warning">Searching your orders for this report<div className="ms-1 spinner-border spinner-border-sm"></div></div> }
                { unableToSync && <div className="ms-3">
                    { paymentError }
                    { !startingPurchase && <>
                        <a className="me-2" href='#' onClick={purchaseReport} >Buy Report</a>
                        if you exited the payment process before completing the purchase
                    </>}
                    { startingPurchase && <div>Restarting purchase...<div className="ms-1 spinner-border spinner-border-sm"></div></div> }
                </div>}
                { unableToSync && <div className="ms-3">
                    <a className="me-2" href={createMailToInquiry(report)}>Contact Us</a>
                    if you think you have already paid for this report and we will look into it
                </div>}
            </>)}
        </div>
    )
}