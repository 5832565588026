import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReports } from "../api";

import Page from "./Page";
import { formatLatestRunDateForReport, getLatestDownloadUrlForReport, getLatestRun } from "./ReportViewPage";

function stopPropagation(e) { e.stopPropagation() }

function ReportSearchEntry({report, highlightText}) {
    const navigate = useNavigate()

    function highlight(text) {
        if (highlightText.length<3) return text

        const parts = []
        const upperText = text.toUpperCase()
        let index = 0
        let nextIndex = 0
        do {
            nextIndex = upperText.indexOf(highlightText.toUpperCase(), index)
            if (nextIndex === -1) {
                parts.push(<span key={index}>{text.slice(index)}</span>)
            }
            else {
                if (nextIndex > index) parts.push(<span key={index}>{text.slice(index, nextIndex)}</span>)
                parts.push(<mark key={nextIndex} className="p-0">{text.slice(nextIndex, nextIndex+highlightText.length)}</mark>)
                index = nextIndex + highlightText.length
            }
        } while(nextIndex !== -1)
        return parts
    }


    const locationText = report.quarterSections
    const dateText = formatLatestRunDateForReport(report)
    const downloadLatestUrl = getLatestDownloadUrlForReport(report)

    return (
        <div className="card mb-2">
            <div className="card-body" onClick={() => navigate(`/report/${report.id}`)}>
                <div className="card-title">
                    <h4 className="me-4">
                        {highlight(report.projectName)}
                        { downloadLatestUrl && (
                            <a href={downloadLatestUrl} download onClick={stopPropagation}>
                                <i className="bi-download position-absolute top-0 end-0 m-3"></i>
                            </a>
                        )}
                    </h4>
                </div>
                <div className="card-subtitle h6 mb-2">{highlight(dateText)}</div>
                <div>{highlight(locationText)}</div>
            </div>
        </div>
    )
}

const dateInc = (r1, r2) => {
    let r1Latest = getLatestRun(r1)
    let r2Latest = getLatestRun(r2)
    if (r1Latest && r2Latest) return new Date(r1Latest).getTime() - new Date(r2Latest).getTime() 
    if (!r1Latest && !r2Latest) return 0
    if (!r1Latest) return -1
    return 1
}

const dateDec = (r1, r2) => dateInc(r2, r1)

const nameInc = (r1, r2) => {
    if (r1.projectName > r2.projectName) return 1
    if (r1.projectName < r2.projectName) return -1
    return 0
}

const nameDec = (r1, r2) => nameInc(r2, r1)

export default function ReportListPage() {
    const [allReports, setAllReports] = useState(null)
    const [searchFragment, setSearchFragment] = useState("")
    const [filteredReports, setFilteredReports] = useState([])
    const [fetchError, setFetchError] = useState('')
    const [sortFn, setSortFn] = useState(() => dateDec)
    const [showSortPopup, setShowSortPopup] = useState(false)

    function updateSearchFragment(e) {
        setSearchFragment(e.target.value)
    }

    function updateSort(newSort) {
        setSortFn(() => newSort)
        setShowSortPopup(false)
    }

    const navigate = useNavigate()

    useEffect(() => {
        getReports().then(setAllReports).catch((err) => {
            setFetchError(err.message)
        })
    }, [])

    useEffect(() => {
        function filterReport(report) {
            if (searchFragment.length < 3) return true
            const searchText = (
                report.projectName+" "+
                report.quarterSections+" "+
                formatLatestRunDateForReport(report)
            ).toUpperCase()
            return searchText.indexOf(searchFragment.toUpperCase()) !== -1
        }

        if (allReports) {
            const newFilteredReports = allReports.filter(filterReport)
            newFilteredReports.sort(sortFn)
            setFilteredReports(newFilteredReports)
        }
    }, [allReports, searchFragment, sortFn])

    const error = fetchError.length !== 0
    const loading = !error && allReports === null
    const noReports = (allReports !==null && allReports.length === 0)
    const noMatches = (searchFragment.length > 2) && (filteredReports.length === 0)

    const sortPopupStyle = {position: 'absolute', width: '8em', zIndex: 1, right:0 }
    if (!showSortPopup) sortPopupStyle.display = 'none'

    return (
        <Page title="AERAS - My Reports">
            <div className="row bg-body-tertiary justify-content-md-center">
                <div className="col-md-auto">
                    <div className="input-group mb-3">
                        <span className="input-group-text"><i className="bi-search"></i></span>
                        <input type="text" className="form-control" placeholder="Search..." aria-label="Search" 
                            value={searchFragment} onChange={updateSearchFragment}
                        />
                        <div>
                            <button className="btn btn-tertiary" onClick={()=>setShowSortPopup(true)}><i className="bi-sort-up"></i></button>
                            <div className='card' style={sortPopupStyle}>
                                <button className='btn text-start' onClick={()=>updateSort(nameInc)}><i className="bi-sort-alpha-down me-2"></i>Name</button>
                                <button className='btn text-start' onClick={()=>updateSort(nameDec)}><i className="bi-sort-alpha-up-alt me-2"></i>Name</button>
                                <button className='btn text-start' onClick={()=>updateSort(dateInc)}><i className="bi-sort-numeric-down me-2"></i>Recent</button>
                                <button className='btn text-start' onClick={()=>updateSort(dateDec)}><i className="bi-sort-numeric-up-alt me-2"></i>Recent</button>
                                <hr className='mt-0 mb-0'/>
                                <button className='btn' onClick={()=>setShowSortPopup(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-auto text-end mb-2">
                    <button className="btn btn-primary" onClick={() => navigate('/createReport')}>Create Report</button>
                </div>
            </div>
            
            { error &&  <i className="text-danger">There was a problem fetching your reports... {fetchError}</i> }
            { loading &&  <i>Loading....</i> }
            { noReports &&  <i>Create your first report by clicking "Create Report"</i> }
            { noMatches && <i>No matching reports...</i> }
            { filteredReports.map((report, index) => (<ReportSearchEntry report={report} highlightText={searchFragment} key={index}/>))}
        </Page>
    )
}