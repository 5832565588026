import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReportByOrderId } from "../api";

export default function ReportByOrderId() {
    const { orderId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getReportByOrderId(orderId)
        .then((report) => {
            navigate('/report/'+report.id)
        })
        .catch((err) => {
            console.log(err)
        })
    }, [orderId])

    return (
        <div> Looking up order {orderId}....</div>
    )
}