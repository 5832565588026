import { useEffect, useState } from 'react' 
import { getCurrentUser as apiGetCurrentUser } from '../api.js'
import { useLocation } from 'react-router-dom'

export default function LoggingInBanner() {    
    const [error, setError] = useState() 
    const location = useLocation()

    useEffect(() => {
        apiGetCurrentUser().then((user) => { 
            if (!user) {
                const loginRedirectPath = 'https://aerasenv.com/?reportPath='+location.pathname
                window.location.href  = loginRedirectPath
            }
        }).catch((err)=>{
            console.log('Checking for logged in user had error', err)
            setError(err.message)
        })   
    },[location.pathname])

    return (
        <div className="container-fluid text-center">
            <div className="row">
                <div className="vh-25"></div>
            </div>
            <div className="row align-items-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-6 mx-auto">
                    <img className="img-fluid" src='/images/AERAS.png' alt='AERAS'/>
                </div>
            </div>
            <div className="row mt-3 align-items-center">
                <div className="col">{(error) ? error : "Loading..."}</div>
            </div>
        </div>
    )
}