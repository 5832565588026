import { useState } from 'react';
import './LoginPage.css';
import { login } from '../api';

export default function LoginPage ({ setLoggedInUser }) {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const tryLogin = () => {
        login(username, password)
        .then((user) => {
            setError('')
            setLoggedInUser(user)
        })
        .catch((error) => {
            setError(error.message)
        })
    }

    const updateValue = (setter) => {
        return (event) => { setter(event.target.value) }
    }

    return (
        <div className="container-fluid text-center">
            <div className="row">
                <div className="vh-25"></div>
            </div>
            <div className="row align-items-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-6 mx-auto">
                    <img className="img-fluid" src='/images/AERAS.png' alt='AERAS'/>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-10 col-sm-6 col-md-4 col-lg-3">
                    <div >Sign in to continue</div>
                    <div className="form-floating mt-2">
                        <input 
                            className="form-control" 
                            type="text" 
                            id="username" 
                            name="username" 
                            placeholder="User name"
                            value={username}
                            onChange={updateValue(setUsername)}
                        />
                        <label htmlFor="username">User name</label>
                    </div>
                    <div className="form-floating mt-2">
                        <input 
                            className="form-control" 
                            type="password" 
                            id="password" 
                            name="password" 
                            placeholder="Password"
                            value={password}
                            onChange={updateValue(setPassword)}
                        />
                        <label htmlFor="password">Password</label>
                    </div>
                    <div className="mt-2">
                        { error.length ? <div className="text-danger mb-1">{error}</div> : null}
                        <button type="submit" className="btn btn-primary w-75" onClick={tryLogin}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}