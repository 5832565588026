import { useState } from "react"

export default function EditUser({user, onSave, onCancel, saveError, noTitle}) {
    const [fullName, setFullName] = useState(user.fullName)
    const [email, setEmail] = useState(user.email)
    const [companyName, setCompanyName] = useState(user.companyName || '')
    const [address, setAddress] = useState(user.address || '')
    const [city, setCity] = useState(user.city || '')
    const [province, setProvince] = useState(user.province || '')
    const [postalCode, setPostalCode] = useState(user.postalCode || '')

    const updateValue = (setter) => {
        return (event) => { setter(event.target.value) }
    }

    const onSaveClicked = () => {
        onSave?.({
            id: user.id,
            fullName,
            email,
            companyName,
            address,
            city,
            province,
            postalCode
        })
    }

    const onCancelClicked = () => {
        onCancel?.()
    }

    return (
        <div className="row pt-3">
            <div className="col-12 h3">Edit Profile</div>
            <div className="col-12 col-sm-6 mb-3 align-self-end">
                <div className="form-floating">
                    <input type="text"  className="form-control" name="contactName" id="contactName" 
                        value={fullName} onChange={updateValue(setFullName)}
                    />
                    <label htmlFor="contactName">Contact Name</label>
                </div>
            </div>
            <div className="col-12 col-sm-6 mb-3">
                <span className="form-text ms-2">Your email address cannot be changed</span>
                <div className="form-floating">
                    <input type="email" disabled={true} className="form-control w-100" name="email" id="email"
                        value={email} onChange={updateValue(setEmail)}
                    />
                    <label htmlFor="email">
                        Email
                    </label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="companyName" id="companyName" 
                        value={companyName} onChange={updateValue(setCompanyName)} 
                    />
                    <label htmlFor="companyName">Company Name</label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="address" id="address" 
                        value={address} onChange={updateValue(setAddress)}
                    />
                    <label htmlFor="address">Address</label>
                </div>
            </div>
            <div className="col-4 mb-3 form-floating">
                <div className="form-floating">
                    <input type="text" className="form-control" name="city" id="city" 
                        value={city} onChange={updateValue(setCity)} 
                    />
                    <label htmlFor="city">City</label>
                </div>
            </div>
            <div className="col-4 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="province" id="province" 
                        value={province} onChange={updateValue(setProvince)}
                    />
                    <label htmlFor="province">Province</label>
                </div>
            </div>
            <div className="col-4 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="postalCode" id="postalCode" 
                        value={postalCode} onChange={updateValue(setPostalCode)} 
                    />
                    <label htmlFor="postalCode">Postal Code</label>
                </div>
            </div>
            { saveError ? <div className="col-12 text-danger">{saveError.message}</div> : null}
            <div className="col-12 mb-3">
                <button className="btn btn-primary" onClick={onSaveClicked}>Save</button>
                <button className="btn btn-seconday" onClick={onCancelClicked}>Cancel</button>
            </div>
        </div>
    )
}  