import { useEffect, useState } from 'react' 
import { useParams } from 'react-router-dom';
import { loginWithWixLoginKey } from "../api"

export default function WixLoginRedirect({ setLoggedInUser }) {

    const { loginKey } = useParams()

    const [error, setError] = useState() 

    useEffect(() => {
        loginWithWixLoginKey(loginKey)
        .then(setLoggedInUser)
        .catch((err) => {
            setError("Unable to login, user not authorized for report portal use")
            console.log(err)
        })
    },[loginKey, setLoggedInUser])

    return (
        <div className="container-fluid text-center">
            <div className="row">
                <div className="vh-25"></div>
            </div>
            <div className="row align-items-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-6 mx-auto">
                    <img className="img-fluid" src='/images/AERAS.png' alt='AERAS'/>
                </div>
            </div>
            <div className="row mt-3 align-items-center">
                <div className="col">{(error) ? error : "Logging in to reporting system..."}</div>
            </div>
        </div>
    )
}