
export function parseQuarter(qqsstttrrWm) {
    if (/[N,S][W,E]-\d\d-\d\d\d-\d\d-W\dM/.test(qqsstttrrWm)) {
        let parts = qqsstttrrWm.split('-')
        const quarter = parts[0]
        const sec = Number(parts[1])
        const twp = Number(parts[2])
        const rge = Number(parts[3])
        const meridian = Number(parts[4].charAt(1))
        return {quarter, sec, twp, rge, meridian}
    }
    else {
        throw new Error(`Could not parse quarter section from ${qqsstttrrWm}, expected format 'QQ-SS-TTT-RR-WmM'`)
    }
}
