import { useContext, useState } from 'react'
import LoginContext from '../LoginContext'
import { saveUser } from '../api'

function CompanyView({ reportFor, onEdit }) {
    const hasCompany = () => reportFor.companyName?.length
    return (
        !hasCompany() ? (<>
            <button className="btn btn-tertiary float-end" onClick={onEdit}>
                <i className="bi-pencil me-2"></i>Edit
            </button>
            <p className="mt-3"><i>No company information in profile</i></p>
        </>) : (<>
            <div className="row mt-3" >
                <div className="col">
                    <button className="btn btn-tertiary float-end" onClick={onEdit}>
                        <i className="bi-pencil me-2"></i>Edit
                    </button>
                    <h4 className="mb-0">{reportFor.companyName}</h4>
                    <div>{reportFor.address}</div>
                    <div>{reportFor.city} {reportFor.province} {reportFor.postalCode}</div>
                </div>
            </div>
        </>)
    )
}

function CompanyEdit({ reportFor, onSave, onCancel }) {
    const {user, refreshUser} = useContext(LoginContext)

    const [companyName, setCompanyName] = useState(reportFor.companyName || '')
    const [address, setAddress] = useState(reportFor.address || '')
    const [city, setCity] = useState(reportFor.city || '')
    const [province, setProvince] = useState(reportFor.province || '')
    const [postalCode, setPostalCode] = useState(reportFor.postalCode || '')
    const [saveDefault, setSaveDefault] = useState( companyName.length === 0)

    const updateValue = (setter) => {
        return (event) => { setter(event.target.value) }
    }

    const saveClicked = () => {
        const companyInfo = {
            companyName, address, city, province, postalCode
        }
        onSave(companyInfo)
        if (saveDefault) {
            saveUser({ id: user.id, ...companyInfo })
            .then(refreshUser)
            .catch((err) => {
                console.log('Error saving user', err)
            })
        }
    }

    return (<>
        <div className="row mt-3" >
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="companyName" id="companyName" 
                        value={companyName} onChange={updateValue(setCompanyName)} 
                    />
                    <label htmlFor="companyName">Company Name</label>
                </div>
            </div>
            <div className="col-12 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="address" id="address" 
                        value={address} onChange={updateValue(setAddress)}
                    />
                    <label htmlFor="address">Address</label>
                </div>
            </div>
            <div className="col-4 mb-3 form-floating">
                <div className="form-floating">
                    <input type="text" className="form-control" name="city" id="city" 
                        value={city} onChange={updateValue(setCity)} 
                    />
                    <label htmlFor="city">City</label>
                </div>
            </div>
            <div className="col-4 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="province" id="province" 
                        value={province} onChange={updateValue(setProvince)}
                    />
                    <label htmlFor="province">Province</label>
                </div>
            </div>
            <div className="col-4 mb-3">
                <div className="form-floating">
                    <input type="text" className="form-control" name="postalCode" id="postalCode" 
                        value={postalCode} onChange={updateValue(setPostalCode)} 
                    />
                    <label htmlFor="postalCode">Postal Code</label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <button className="btn btn-secondary" onClick={saveClicked}>
                    Update
                </button>
                <button className="btn btn-teritary" onClick={onCancel}>
                    Cancel
                </button>
            </div>
            <div className="col-auto">
                <label className="me-2" htmlFor="saveDefault">Save as Default</label>
                <input type="checkbox" name="saveDefault"
                    checked={saveDefault} 
                    onChange={() => setSaveDefault(!saveDefault)}
                />
            </div>
        </div>
    </>)
}

export default function ReportForView({reportFor, setReportFor, editing, setEditing}) {

    function updateCompany(newCompanyInfo) {
        console.log('Updating', newCompanyInfo)
        setReportFor({ ...reportFor, ...newCompanyInfo})
        setEditing(false)
    }

    function onEdit() {
        setEditing(true)
    }

    function cancelEdit() {
        setEditing(false)
    }

    return ( 
        <div className="row pt-3 pb-3">
            <div className="col-12">
                <div>
                    <h3 className="mb-0">{reportFor.fullName}</h3>    
                    <small>{reportFor.email}</small>
                </div>
                {!editing && <CompanyView reportFor={reportFor} onEdit={onEdit}/>}
                {editing && <CompanyEdit reportFor={reportFor} onSave={updateCompany} onCancel={cancelEdit}/>}
            </div>
        </div>
    )
}