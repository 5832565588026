let unauthorizedCallback = () => {}

async function postOrDie(url, body, errorMessage) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
    if (response.status === 401) {
        unauthorizedCallback()
    }
    if (response.status !== 200) {
        const messageText = await response.text()
        throw new Error(errorMessage + ' ' + messageText)
    }
    return await response.json()
}

async function getOrDie(url, errorMessage) {
    const response = await fetch(url)
    if (response.status === 401) {
        unauthorizedCallback()
    }
    if (response.status !== 200) {
        const messageText = await response.text()
        throw new Error(errorMessage + ' ' + messageText)
    }
    return await response.json()
}

export function setUnauthorizedCallback(cb) {
    unauthorizedCallback = cb
}

export function loginWithWixLoginKey(loginKey) {
    return postOrDie('/wix-login-with-key', { loginKey }, 'Member tokens invalid')
}

export function login(username, password) {
    return postOrDie("/api/auth/login", { username, password}, 'Login failed')
}

export function logout() {
    return postOrDie("/api/auth/logout", {}, 'Logout failed')
}

export async function getCurrentUser() {
    const response = await fetch("/api/auth/currentUser")
    if (response.status !== 200) {
        throw new Error('User fetch failed')
    }
    return await response.json()
}

export async function saveUser(user) {
    return postOrDie(`/api/user/${user.id}`, user, 'Save failed')
}

export async function getReports() {
    return getOrDie('/api/report')
}

export async function getReport(reportId) {
    return getOrDie(`/api/report/${reportId}`)
}

export async function createNewReport(reportDetail) {
    return postOrDie('/api/report', reportDetail, "Error creating report: ")
}

export async function getPaymentUrlForReport(reportId) {
    return getOrDie(`/api/report/${reportId}/paymentUrl`)
}

export async function getSyncOrderForReport(reportId) {
    return getOrDie(`/api/report/${reportId}/syncOrder`)
}

export async function getReportByOrderId(orderId) {
    return getOrDie(`/api/report/order-id/${orderId}`, "Order not found")
}

export async function startNewRun(reportId) {
    return getOrDie('/api/report/'+reportId+'/newRun', "Report run failed: ")
}

export async function validateQuarterSection(quarter) {
    return getOrDie('/api/report/validateQuarter?quarter='+quarter, "Validation Error:")
}

export function getWsUrlForReportRunLog(reportId, runId) {
    const wsProto = window.location.protocol.startsWith('https') ? 'wss' : 'ws'
    const wsUrl = wsProto + '://'+window.location.host+"/api/report/"+reportId+"/logWs/"+runId
    return wsUrl
}