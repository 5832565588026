export default function UserView({user}) {

    const hasCompany = () => user.companyName?.length
    return (
        <div className="row pt-3 pb-3">
            <div className="col-12">
                <div>
                    <h3 className="mb-0">{user.fullName}</h3>    
                    <small>{user.email}</small>
                </div>
                {!hasCompany() ? (
                    <p className="mt-3"><i>No company information in profile</i></p>
                ) : (
                    <div className="mt-3">
                        <h4 className="mb-0">{user.companyName}</h4>
                        {user.address} <br/>
                        {user.city} {user.province} {user.postalCode}
                    </div>
                )}
            </div>
        </div>
    )
}