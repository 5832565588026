import { useEffect, useState } from "react";

export default function OnlineReportLog({ wsUrl, onClose }) {

    const [lines, setLines] = useState([])
    const [complete, setComplete] = useState(false)

    useEffect(() => {
        let cancelled = false
        const cachedLines = []
        const socket = new WebSocket(wsUrl)
        socket.addEventListener('message', (event) => {
            cachedLines.push(event.data)
            setLines([...cachedLines])
        })
        socket.addEventListener('close', () => {
            if (!cancelled) setComplete(true)
        })
        socket.addEventListener('error', (event) => {
            console.log("WebSocket error", event)
        })
        return () => {
            cancelled = true
            socket.close()
        }
    }, [wsUrl])

    return (<>
        <code>
            {lines.map((line, index) => (
                <div key={'line'+index}>{line}</div>
            ))}
        </code>
        { complete && (
            <button className="btn btn-secondary btn-sm mt-1" onClick={onClose}>Close Log</button>
        )}
    </>)
}