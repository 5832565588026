import { useEffect, useState } from 'react'

import LoginContext from './LoginContext.js'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { logout as apiLogout, getCurrentUser as apiGetCurrentUser, setUnauthorizedCallback } from './api.js'

import LoginPage from './pages/LoginPage.js';
import ReportListPage from './pages/ReportListPage.js';
import ProfilePage from './pages/ProfilePage.js';
import CreateReportPage from './pages/CreateReportPage.js';
import ReportViewPage from './pages/ReportViewPage.js';
import WixLoginRedirect from './pages/WixLoginRedirect.js';

import './App.css';
import WixNavigate from './components/WixNavigate.js';
import ReportByOrderId from './components/ReportByOrderId.js';
import LoggingInBanner from './pages/LoggingInBanner.js';

function App() {

  const [loggedInUser, setLoggedInUser] = useState('')
  const logout = () => {
    const logoutHome = () => {
      window.location.href="https://aerasenv.com/?logout=true"
    }
    apiLogout().then(logoutHome, logoutHome)
  }
  const refreshUser = () => {
    apiGetCurrentUser().then(setLoggedInUser).catch((err)=>{
      console.log('Refreshing user had error', err)
    })
  }
  
  useEffect(() => {
    setUnauthorizedCallback(refreshUser)
    refreshUser()
  }, [])

  if (!loggedInUser) return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="wix-login/:loginKey" element={<WixLoginRedirect setLoggedInUser={setLoggedInUser}/>}/>
          <Route path="login" element={<LoginPage setLoggedInUser={setLoggedInUser}/>} />
          <Route path="*" element={<LoggingInBanner />}/>
          <Route path="/" element={<Navigate to="/login" />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
  
  //

  return (
    <LoginContext.Provider value={{ user: loggedInUser, logout, refreshUser}}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Navigate to='/'/>}/>
            <Route path="reports" element={<ReportListPage/>} />
            <Route path="report/:reportId" element={<ReportViewPage />} />
            <Route path="reportByOrderId/:orderId" element={<ReportByOrderId />} />
            <Route path="createReport" element= {<CreateReportPage />} />
            <Route path="profile" element={<ProfilePage/>} />
            <Route path="wix-login/:loginKey" element={<WixNavigate />} />
            <Route index element={<ReportListPage/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoginContext.Provider>
  ) 
}

export default App;
